.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
}

form {
  display: flex;
  flex-direction: column;
}

button[type="submit"] {
  margin-top: 16px;
  margin-bottom: 32px;
}

textarea {
  width: 320px;
  min-height: 320px;
}
